/**
 * @fileoverview gRPC-Web generated client stub for gamecenter.private
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.3
// source: pub-gamecenter/private/console.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var pub$gamecenter_public_v1_resource_pb = require('../../pub-gamecenter/public/v1/resource_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')
const proto = {};
proto.gamecenter = {};
proto.gamecenter.private = require('./console_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.gamecenter.private.GameCenterConsoleClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.gamecenter.private.GameCenterConsolePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.ListGameBasicInfoRequest,
 *   !proto.gamecenter.private.ListGameBasicInfoResponse>}
 */
const methodDescriptor_GameCenterConsole_ListGameBasicInfo = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/ListGameBasicInfo',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.ListGameBasicInfoRequest,
  proto.gamecenter.private.ListGameBasicInfoResponse,
  /**
   * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.ListGameBasicInfoResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.ListGameBasicInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.ListGameBasicInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.listGameBasicInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/ListGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_ListGameBasicInfo,
      callback);
};


/**
 * @param {!proto.gamecenter.private.ListGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.ListGameBasicInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.listGameBasicInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/ListGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_ListGameBasicInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.GetGameBasicInfoRequest,
 *   !proto.gamecenter.private.GetGameBasicInfoResponse>}
 */
const methodDescriptor_GameCenterConsole_GetGameBasicInfo = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/GetGameBasicInfo',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.GetGameBasicInfoRequest,
  proto.gamecenter.private.GetGameBasicInfoResponse,
  /**
   * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.GetGameBasicInfoResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.GetGameBasicInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.GetGameBasicInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.getGameBasicInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/GetGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_GetGameBasicInfo,
      callback);
};


/**
 * @param {!proto.gamecenter.private.GetGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.GetGameBasicInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.getGameBasicInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/GetGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_GetGameBasicInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.UpsertGameBasicInfoRequest,
 *   !proto.gamecenter.private.UpsertGameBasicInfoResponse>}
 */
const methodDescriptor_GameCenterConsole_UpsertGameBasicInfo = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/UpsertGameBasicInfo',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.UpsertGameBasicInfoRequest,
  proto.gamecenter.private.UpsertGameBasicInfoResponse,
  /**
   * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.UpsertGameBasicInfoResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.UpsertGameBasicInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.UpsertGameBasicInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.upsertGameBasicInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/UpsertGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_UpsertGameBasicInfo,
      callback);
};


/**
 * @param {!proto.gamecenter.private.UpsertGameBasicInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.UpsertGameBasicInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.upsertGameBasicInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/UpsertGameBasicInfo',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_UpsertGameBasicInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.GetGameConfigRequest,
 *   !proto.gamecenter.private.GetGameConfigResponse>}
 */
const methodDescriptor_GameCenterConsole_GetGameConfig = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/GetGameConfig',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.GetGameConfigRequest,
  proto.gamecenter.private.GetGameConfigResponse,
  /**
   * @param {!proto.gamecenter.private.GetGameConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.GetGameConfigResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.GetGameConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.GetGameConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.GetGameConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.getGameConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/GetGameConfig',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_GetGameConfig,
      callback);
};


/**
 * @param {!proto.gamecenter.private.GetGameConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.GetGameConfigResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.getGameConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/GetGameConfig',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_GetGameConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.UpsertGameConfigRequest,
 *   !proto.gamecenter.private.UpsertGameConfigResponse>}
 */
const methodDescriptor_GameCenterConsole_UpsertGameConfig = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/UpsertGameConfig',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.UpsertGameConfigRequest,
  proto.gamecenter.private.UpsertGameConfigResponse,
  /**
   * @param {!proto.gamecenter.private.UpsertGameConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.UpsertGameConfigResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.UpsertGameConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.UpsertGameConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.UpsertGameConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.upsertGameConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/UpsertGameConfig',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_UpsertGameConfig,
      callback);
};


/**
 * @param {!proto.gamecenter.private.UpsertGameConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.UpsertGameConfigResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.upsertGameConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/UpsertGameConfig',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_UpsertGameConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.gamecenter.private.DeleteGameRequest,
 *   !proto.gamecenter.private.DeleteGameResponse>}
 */
const methodDescriptor_GameCenterConsole_DeleteGame = new grpc.web.MethodDescriptor(
  '/gamecenter.private.GameCenterConsole/DeleteGame',
  grpc.web.MethodType.UNARY,
  proto.gamecenter.private.DeleteGameRequest,
  proto.gamecenter.private.DeleteGameResponse,
  /**
   * @param {!proto.gamecenter.private.DeleteGameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.gamecenter.private.DeleteGameResponse.deserializeBinary
);


/**
 * @param {!proto.gamecenter.private.DeleteGameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.gamecenter.private.DeleteGameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.gamecenter.private.DeleteGameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gamecenter.private.GameCenterConsoleClient.prototype.deleteGame =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/DeleteGame',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_DeleteGame,
      callback);
};


/**
 * @param {!proto.gamecenter.private.DeleteGameRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.gamecenter.private.DeleteGameResponse>}
 *     Promise that resolves to the response
 */
proto.gamecenter.private.GameCenterConsolePromiseClient.prototype.deleteGame =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gamecenter.private.GameCenterConsole/DeleteGame',
      request,
      metadata || {},
      methodDescriptor_GameCenterConsole_DeleteGame);
};


module.exports = proto.gamecenter.private;

