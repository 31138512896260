import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'

import { getReceptionClient } from './reception'

function getApiUrl(gameCode, path) {
  return gameCode ? `/v1/games/${gameCode}/terms${path}` : `/v1/common-terms${path}`
}

export async function getTermsList(env, gameCode, region) {
  const client = await getReceptionClient(env)
  const resp = await client.get(getApiUrl(gameCode, `/region/${region}/version`))
  return camelcaseKeysDeep(resp.data)
}

export async function getTerms(env, gameCode, region, versionId) {
  const client = await getReceptionClient(env)
  const resp = await client.get(getApiUrl(gameCode, `/region/${region}/version/${versionId}`))
  return camelcaseKeysDeep(resp.data)
}

export async function postTerms(env, gameCode, terms) {
  const client = await getReceptionClient(env)
  const resp = await client.post(getApiUrl(gameCode, ''), decamelizeKeysDeep(terms))
  return camelcaseKeysDeep(resp.data)
}

export async function putTermsDraft(env, gameCode, region, versionId, terms) {
  const client = await getReceptionClient(env)
  const resp = await client.put(
    getApiUrl(gameCode, `/region/${region}/draft-version/${versionId}`),
    decamelizeKeysDeep(terms),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function putTermsContent(env, gameCode, region, versionId, terms) {
  const client = await getReceptionClient(env)
  const resp = await client.put(
    getApiUrl(gameCode, `/region/${region}/version/${versionId}`),
    decamelizeKeysDeep(terms),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function copyTermsVersion(env, gameCode, region, versionId) {
  const client = await getReceptionClient(env)
  const resp = await client.post(getApiUrl(gameCode, `/region/${region}/version/${versionId}/copy`))
  return camelcaseKeysDeep(resp.data)
}

export async function deleteTermsDraft(env, gameCode, region, versionId) {
  const client = await getReceptionClient(env)
  const resp = await client.delete(
    getApiUrl(gameCode, `/region/${region}/draft-version/${versionId}`),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function inactivateTerms(env, gameCode, region, termsId) {
  const client = await getReceptionClient(env)
  const resp = await client.post(
    getApiUrl(gameCode, `/region/${region}/terms/${termsId}/inactivate`),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function activateTermsDraft(env, gameCode, region, versionId) {
  const client = await getReceptionClient(env)
  const resp = await client.post(
    getApiUrl(gameCode, `/region/${region}/draft-version/${versionId}/activate`),
  )
  return camelcaseKeysDeep(resp.data)
}

export async function reorderTerms(env, gameCode, region, termsIdToOrder) {
  const client = await getReceptionClient(env)
  const resp = await client.post(
    getApiUrl(gameCode, `/region/${region}/reorder`),
    decamelizeKeysDeep({ termsIdToOrder }),
  )
  return camelcaseKeysDeep(resp.data)
}
