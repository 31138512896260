import axios from 'axios'
import { inflight } from '../../utils'
import localforage from 'localforage'
import decamelizeKeysDeep from 'decamelize-keys-deep'

export async function getGameSummaries() {
  return (await axios.get('/portal/game_summaries/')).data
}

export const getGame = inflight(async function (gameCode) {
  try {
    const resp = await axios.get(`/portal/games/${gameCode}/`)
    return resp.data
  } catch (e) {
    if (e.response.status === 404) {
      await localforage.removeItem('gamecode')
      location.href = '/'
    }
    throw e
  }
})

export async function getGameUserGroups(gameCode) {
  return (await axios.get(`/portal/game_user_groups/${gameCode}/`)).data
}

export async function patchGameUserGroups(gameCode, groups) {
  return (await axios.patch(`/portal/game_user_groups/${gameCode}/`, groups)).data
}

export async function patchGame(gameCode, enabledLanguages) {
  const data = {}
  if (enabledLanguages) {
    data['enabled_languages'] = enabledLanguages
  }

  return (await axios.patch(`/portal/games/${gameCode}/`, data)).data
}

export async function uploadAppIcon(gameCode, file) {
  const form = new FormData()
  form.append('app_icon', file)

  return (await axios.patch(`/portal/games/${gameCode}/`, form)).data
}

// 포탈 백엔드를 거쳐서 리셉션 서버로 API Key 정보 조회
export async function getApiKey(gameId, env) {
  return (await axios.get(`/portal/game/apikey?game_id=${gameId}&env=${env}`)).data
}

// 포탈 백엔드를 거쳐서 리셉션 서버로 API Key 생성 요청
export async function postApiKey(
  gameId,
  env,
  keyType, // API 키의 형태 (client or server)
  key, // 생성할 API Key 값
  secret, // 생성할 API Secret 값
  comment, // API Key 생성 사유
) {
  const data = {
    gameId,
    env,
    keyType,
    key,
    secret,
    comment,
  }

  return (await axios.post('/portal/game/apikey/', decamelizeKeysDeep(data))).data
}

// 포탈 백엔드 게임 설정 정보 설정
export async function postGameSettingData(
  gameId,
  appEnv,
  localizationDefaultUrl,
  localizationPath,
) {
  const data = {
    gameId,
    appEnv,
    localizationDefaultUrl,
    localizationPath,
  }

  return (await axios.post('/portal/game/game_setting/', decamelizeKeysDeep(data))).data
}
