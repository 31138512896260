import axios from 'axios'
import { inflight } from '../../utils'

export async function getApiVersion() {
  return (await axios.get('/portal/version/')).data
}

export async function getUserInfo() {
  return (await axios.get('/portal/userinfo/')).data
}

export const getUser = inflight(async function (userId) {
  return (await axios.get(`/portal/users/${userId}/`)).data
})

export async function getUsers() {
  return (await axios.get('/portal/users/')).data
}

export async function getBlob(url) {
  const opt = { responseType: 'blob' }

  return (await axios.get(url, opt)).data
}

export async function resetAuthToken() {
  return (await axios.post('/portal/auth_token/')).data
}

export async function genAuthToken() {
  return (await axios.post('/portal/auth_token/')).data
}

export async function uploadDocumentFile(file) {
  const formData = new FormData()
  formData.append('file', file)

  return (await axios.post('/portal/document_files/', formData)).data
}

export async function postPrivacy(game, privacy, languageCode) {
  const data = {
    game: game,
    contents: privacy,
    language_code: languageCode,
  }
  return (await axios.post('/portal/privacy/', data)).data
}

export async function postEUTerms(game, terms, languageCode) {
  const data = {
    game: game,
    contents: terms,
    language_code: languageCode,
  }
  return (await axios.post('/portal/eu_terms/', data)).data
}

export async function syncTranslation(translationKeys, game) {
  const data = {
    translation_keys: translationKeys,
    game: game,
  }

  return (await axios.post('/portal/sync-translation/', data)).data
}

export async function postCreateShortcut(file) {
  return (await axios.post(`/portal/shortcut/${file.uuid}/`)).data
}

export async function getDevPlayCsLog(targetEnv, gameCode, memberId, logType, endDt) {
  const data = {
    env: targetEnv,
    game: gameCode,
    member_id: memberId,
    log_type: logType,
    end_dt: endDt,
  }
  return (await axios.post('/portal/v2/devplay/cslog/', data)).data
}

export async function getRefundHistories(env, gameCode, startTime, endTime, platform) {
  return (
    await axios.get(
      `/portal/refunds/env/${env}/game/${gameCode}?platform=${platform}&start_time=${startTime}&end_time=${endTime}`,
    )
  ).data
}

export async function getLoginInfo() {
  return (await axios.get('/portal/login_info/')).data
}

export async function debugLogin(email, permissions) {
  await axios.post(
    '/portal/login/',
    {
      email,
      permissions,
    },
    {
      withCredentials: true,
    },
  )
}
